<template>
	<Layout>
		<v-main class="pt-4 pb-10 mb-5" v-if="loaded">
			<section id="slide" class="px-3">
				<section-header :header="event.subtitle" :sub-header="event.title" />
			</section>
		</v-main>
		<v-container v-if="loaded" fluid>
			<v-row align="start" justify="center" v-if="!saving">
				<v-col cols="12" sm="7">
					<h1 class="font-weight-black my-5 text-center">FORMULARIO DE INSCRIPCIÓN</h1>
					<h2 class="mt-5 mb-2 text-center">Tarifas de inscripción</h2>
					<div v-if="event.free">
						<h3>Evento Gratuito, llene con sus datos el formulario de inscripción para formalizar su registro</h3>
					</div>
					<div v-else>
						<div v-if="event.fees != ''">
							<v-container>
								<v-radio-group>
									<template v-for="(item, index) in fees">
										<v-list-item :key="index" :class="(index % 2) ? 'white' : 'blue-grey lighten-5'">
											<v-list-item-action>
												<v-radio :key="index" :value="index" @click="checkFee(item)"></v-radio>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-content v-text="item.description" class="accent--text" />
											</v-list-item-content>
											<v-list-item-action>
												<span class="accent--text">{{ event.currency }} {{ formatedPrice(item.price) }}</span>
											</v-list-item-action>
										</v-list-item>
									</template>
								</v-radio-group>
							</v-container>
						</div>
						<div v-else>
							<p>Próximamente</p>
						</div>

						<v-divider class="my-2" v-if="activities != null && activities != ''"></v-divider>
						<div v-if="activities != null && activities != ''">
							<h2 class="my-3">Tarifas de Actividades Adicionales</h2>
							<h3 class="font-weight-black">Por favor seleccione la(s) Actividad(es) en la(s) que desea participar:</h3>
							<template v-for="(itm, idx) in activities">
								<v-list-item :key="idx" :class="(idx % 2) ? 'white' : 'blue-grey lighten-5'">
									<v-list-item-action>
										<v-checkbox v-model="arrayActivities" :key="idx" :value="itm"
											@click="checkActivity(itm)"></v-checkbox>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-content v-text="itm.description" class="accent--text" />
									</v-list-item-content>
									<v-list-item-action>
										<span class="accent--text">{{ event.currency }} {{ formatedPrice(itm.price) }}</span>
									</v-list-item-action>
								</v-list-item>
							</template>
						</div>
						<v-divider class="my-5" />
						<div class="text-right grey lighten-4 pa-4">
							<h2>TOTAL A PAGAR: {{ event.currency }} {{ formatedPrice(total) }}</h2>
						</div>

					</div>

					<v-divider class="my-2" />

				</v-col>

				<!-- Register Form -->
				<v-col cols="10">
					<!-- <h3 class="font-weight-black accent--text">Formulario de inscripción <br/> -->

					<!-- </h3> -->

					<v-form v-model="formValidity" ref="form">
						<v-row justify="center">
							<v-col cols="12" sm="8">
								<h2 class="my-2">Datos Personales</h2>
								<v-text-field :rules="[rules.required]" v-model="register.name" color="primary" label="Nombre completo"
									prepend-inner-icon="mdi-account-circle" dense filled />
								<v-text-field :rules="[rules.required]" v-model="register.ci" color="primary" label="Cédula Identidad"
									prepend-inner-icon="mdi-card-bulleted" dense filled />
								<v-text-field :rules="[rules.required, rules.email]" v-model="register.email" color="primary"
									label="Correo electrónico" prepend-inner-icon="mdi-email" dense filled />
								<v-text-field :rules="[rules.required]" v-model="register.phone" color="primary" label="Número Celular"
									prepend-inner-icon="mdi-phone" dense filled />
								<v-text-field :rules="[rules.required]" v-model="register.work" color="primary" label="Lugar de trabajo"
									prepend-inner-icon="mdi-hospital-building" dense filled />
								<v-select :rules="[rules.required]" v-model="register.department" prepend-inner-icon="mdi-ballot"
									:items="departments" label="Especialidad" persistent-hint return-object single-line dense
									filled></v-select>
								<!-- <v-text-field :rules="[rules.required]" v-model="register.city" color="primary" label="Ciudad"
									prepend-inner-icon="mdi-bookmark-multiple-outline" dense filled />
								<v-text-field :rules="[rules.required]" v-model="register.state" color="primary" label="Estado"
									prepend-inner-icon="mdi-bookmark-multiple" dense filled /> -->
								<v-text-field :rules="[rules.required]" v-model="register.country" color="primary" label="Pais"
									prepend-inner-icon="mdi-web" dense filled />
								<v-textarea v-if="event.free" v-model="register.notes" color="primary" filled
									prepend-inner-icon="mdi-message-reply-text" label="Notas adicionales" rows="2" />

								<h3 class="my-2">¿PRESENTE EN EL EVENTO?</h3>
									<v-select :rules="[rules.required]" v-model="register.asistencia" prepend-inner-icon="mdi-account-circle"
										:items="['SI', 'NO']" label="Asistencia"
										persistent-hint return-object single-line dense filled>
									</v-select>

							</v-col>

						</v-row>
						<v-row justify="center">
							<v-col cols="12" sm="6" v-if="!event.free">
								<h2 class="my-2">Datos del Pago</h2>

								<ul class="mb-3">
									<li>Si su forma de pago es zelle por favor en NOTAS ADICIONALES indique la fecha de la transacción,
										nombre y correo electrònico del titular </li>
								</ul>
								<div class="mb-5" v-if="event.paypal">
									<v-divider class="my-2"></v-divider>
									<h2 class="font-weight-black">PAGAR CON PAYPAL</h2>
									<div class="my-4 text-center pa-2">
										<a :href="event.paypal" target="_blank">
											<v-img class="mb-2" src="/static/paypal.png" height="40" contain />
											<span class="px-4 py-2 primary white--text rounded elevation-2"><v-icon
													class="mr-2">mdi-paypal</v-icon>PAGAR</span>
										</a>
									</div>
								</div>
								<div v-if="event.zelle_name">
									<v-divider class="my-2"></v-divider>
									<h2 class="font-weight-black">PAGO CON ZELLE</h2>
									<h4>Enviar pago a:</h4>
									<p>
										<span class="" v-text="event.zelle_name" /><br />
										<span class="" v-text="event.zelle_email" /><br />
									</p>
								</div>
								<v-divider class="my-2"></v-divider>
								<h2 class="font-weight-black">FORMA DE PAGO</h2>
								<p>
									<span class="" v-html="event.payment_options"></span><br />
								</p>
							</v-col>
							<v-col cols="12" sm="6" v-if="!event.free">

								<!-- <v-text-field
                    disabled
                    :rules="[rules.required]"
                    v-model="register.fee.description"
                    color="primary"
                    label="Tarifa seleccionada"
                    prepend-inner-icon="mdi-account-cash"
                    dense
                    outlined />
                 -->

								<v-select v-model="register.currency" prepend-inner-icon="mdi-account-cash"
									:items="['Bolivares', 'Dolares', 'Euros', 'Petro', 'Zelle', 'Otro']" label="Seleccione moneda"
									persistent-hint return-object single-line dense filled></v-select>
								<v-text-field v-if="register.currency == 'Bolivares'" v-model="register.paid"
									color="primary" label="Monto pagado en Bs. según tarifa" prepend-inner-icon="mdi-cash-plus" dense
									outlined />

								<v-select v-model="register.payment" prepend-inner-icon="mdi-cash"
									:items="['Efectivo', 'Transferencia', 'Paypal', 'Zelle', 'Otra']" label="Forma de pago" persistent-hint
									return-object single-line dense filled></v-select>

								<v-text-field v-model="register.bank" color="primary" label="Banco / Plataforma"
									prepend-inner-icon="mdi-bank" dense filled />

								<v-text-field v-model="register.confirmation" color="primary"
									label="Nº Comprobante / Confirmación" prepend-inner-icon="mdi-note" dense filled />
								<v-textarea v-model="register.notes" color="primary" filled prepend-inner-icon="mdi-message-reply-text"
									label="Notas adicionales" rows="4" />

									<v-text-field v-model="register.mode" color="primary" label="Cargo/Modalidad"
										prepend-inner-icon="mdi-account-circle" dense filled />

							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="12" sm="6">
								<div class="text-center">


									<!-- <v-alert
                    type="success"
                    v-if="snackbar"
                  >
                    <strong>Su registro ha sido procesado satisfactoriamente!</strong>
                  </v-alert> -->

									<v-btn :disabled="!formValidity" class="white--text" large color="primary" @click.stop="save">
										<v-icon class="mr-2">mdi-account-circle</v-icon> PROCESAR INSCRPCION
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-form>

				</v-col>
				<!-- .Register Form -->
			</v-row>
		</v-container>
		<main-loaded :loaded="loaded" />

	</Layout>
</template>

<script>
import numeral from "numeral";
import { db, functions } from '@/main'
import eventService from '@/services/EventService'
import MainLoaded from '@/components/registro/MainLoaded.vue';
const _REGISTER = {
	id: Math.floor(Date.now() / 1000).toString(),
	name: '',
	ci: '',
	phone: '',
	email: '',
	work: '',
	department: '',
	city: '',
	state: '',
	country: 'Venezuela',
	currency: '',
	payment: '',
	bank: '',
	fee: {
		description: '',
		price: ''
	},
	activities: [],
	paid: '0',
	confirmation: '',
	notes: '',
	status: 'CONFIRMADO',
	mode: 'Participante',
	asistencia: 'SI'
}
export default {
	components: { MainLoaded },
	name: 'register',
	mounted() {
		this.initData()
		this.getData()
	},

	data: () => ({
		saving: false,
		snackbar: false,
		event: {},
		fee: '',
		fees: [],
		activities: [],
		activitiesSelected: [],
		loaded: false,
		register: _REGISTER,
		departments: eventService.getEspecialidades(),
		formValidity: false,
		total: 0,
		rules: {
			required: value => !!value || 'Dato Requerido.',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Correo electrónico errado.'
			},
		},
		showActivities: false,
		arrayActivities: [],
	}),
	methods: {
		checkFee(item) {
			this.total = parseInt(this.total) - parseInt(this.register.paid ? this.register.paid : '0')
			this.register.paid = item.price
			this.total = parseInt(this.total) + parseInt(this.register.paid)
			this.register.fee.description = item.description
			this.register.fee.price = item.price
		},
		checkActivity(item) {
			let me = this
			const itm = this.register.activities.find(x => x.id == item.id)
			if (itm) {
				this.total = parseInt(this.total) - parseInt(itm.price)
				const position = this.register.activities.map(function (x) { return x.id; }).indexOf(itm.id)
				this.register.activities.splice(position, 1)
			} else {
				this.register.activities.push({
					description: item.description,
					price: item.price,
					id: item.id
				})
				this.total = parseInt(this.total) + parseInt(item.price)
			}
		},
		async getData() {
			let me = this
			let id = me.$route.params.event_id
			me.event = {}
			me.fees = []
			me.activities = []
			await db.collection('events').doc(id).get().then((res) => {
				if (res.exists) {
					if (res.data().active) {
						me.event = res.data()
					} else {
						this.$router.push('/')
						return
					}
				} else {
					console.log("No such document!");
				}
			});
			let arrayFees = []
			await db.collection('events').doc(id).collection('fees').get().then((snap) => {
				snap.forEach(function (doc) {
					me.fees.push(doc.data())
				});
			});
			let arrayActivities = []
			await db.collection('events').doc(id).collection('activities').get().then((snap) => {
				snap.forEach(function (doc) {
					me.activities.push(doc.data())
				});
			});

			me.loaded = true

		},
		formatedPrice(price) {
			return numeral(price).format('00,000.00');
		},

		save______() {
			console.log("Aqui")
			let id = this.register.id
			this.$router.push(`/event/ticket/${id}`)
		},

		async save() {
			let me = this
			if (this.event.active) {
				this.saving = true
				this.loaded = true
				this.register.total = this.total
				db.collection('events').doc(me.$route.params.event_id).collection('registers').doc(me.register.id).set(me.register)
					.then((res) => {

						console.log("Document successfully stored! ");
						const url = `https://ajpyeventos-intranet-v2.web.app/registro-printer/${this.$route.params.event_id}/${me.register.id}`

						window.open(url, '_blank')
						me.saving = false;
						me.initData()
					})
					.catch((error) => {
						console.error("Error writing document: ", error);
					});
				// ---- pausado await db.collection('audience').doc(this.register.email).set(this.register);
				//const callable = functions.httpsCallable('sendRegister');
// 				return callable({
// 					id: this.register.id,
// 					event_title: this.event.title,
// 					event_subtitle: this.event.subtitle,
// 					free: this.event.free,
// 					name: this.register.name,
// 					ci: this.register.ci,
// 					email: this.register.email,
// 					phone: this.register.phone,
// 					work: this.register.work,
// 					department: this.register.department,
// 					city: this.register.city,
// 					state: this.register.state,
// 					country: this.register.country,
// 					currency: this.register.currency,
// 					currencyEvent: this.event.currency,
// 					payment: this.register.payment,
// 					bank: this.register.bank,
// 					confirmation: this.register.confirmation,
// 					fee: this.register.fee.description + ' ( ' + this.event.currency + ' ' + this.register.fee.price + ')',
// 					paid: this.register.paid,
// 					mode: this.register.mode,
//
// 					showActivities: this.register.activities.length > 0 ? true : false,
// 					activities: this.register.activities,
//
// 					total: this.total,
// 					notes: this.register.notes,
// 				}).then((res) => {
// 					this.initData();
// 					//this.$router.push(`/event/ticket/${id}`)
//
//
// 				}).catch((e) => {
// 					console.log(e);
// 					this.initData();
// 				});
			} else {
				this.$router.push('/')
				return
			}

		},
		async save_() {
			this.saving = true;

			await db.collection('events').doc(this.$route.params.event_id).collection('registers').doc(this.register.id).set(this.register);
			await db.collection('audience').doc(this.register.email).set(this.register);

			this.snackbar = true;

			setTimeout(() => this.snackbar = false, 4000);


			const callable = functions.httpsCallable('sendRegister');

			return callable({
				id: this.register.id,
				event_title: this.event.title,
				event_subtitle: this.event.subtitle,
				free: this.event.free,
				name: this.register.name,
				ci: this.register.ci,
				email: this.register.email,
				phone: this.register.phone,
				work: this.register.work,
				department: this.register.department,
				city: this.register.city,
				state: this.register.state,
				country: this.register.country,

				currency: this.register.currency,
				payment: this.register.payment,
				bank: this.register.bank,
				confirmation: this.register.confirmation,
				fee: this.register.fee.description + ' (' + this.register.fee.price + ')',
				paid: this.register.paid,

				showActivities: this.showActivities,
				activities: this.activitiesSelected,

				total: this.total,
				notes: this.register.notes,
			}).then(() => {

				this.initData();
			}).catch((e) => {
				console.log(e);
				this.initData();
			});
		},
		initData() {
			this.total = 0
			this.register = {
				id: Math.floor(Date.now() / 1000).toString(),
				name: '',
				ci: '',
				phone: '',
				email: '',
				work: '',
				department: '',
				city: '',
				state: '',
				country: 'Venezuela',
				currency: '',
				payment: '',
				bank: '',
				fee: {
					description: '',
					price: ''
				},
				activities: [],
				paid: '',
				confirmation: '',
				notes: '',
				status: 'CONFIRMADO',
				mode: 'Participante',
				asistencia: 'SI'
			};
			this.showActivities = false

		}

	},
	computed: {
		especialidades() {
			return this.eventService.especialidades();
		}
	},
}
</script>

<style></style>

