<template>
	<v-container v-if="show">
      <v-row justify="center">
        <v-col cols="12" sm="8">
          <v-alert
            border="bottom"
            colored-border
            type="info"
            elevation="2" class="pa-5 grey lighten-4">
            <h2 class="text-center">SU INSCRIPCIÓN HA SIDO ENVIADA</h2>
            <h4 class="my-2">En breves minutos recibirá una copia en su correo electrónico, si no está en su bandeja de entrada revise su carpeta de notificaciones y carpeta de spam.</h4>
            <h4 class="mb-5">Cualquier problema no repita la inscripción, comuníquese con ajpyeventos@gmail.com y recibirá soporte para su caso</h4>
          </v-alert>
          <v-divider class="my-5 py-5" />
          <div class="mt-5 text-center" v-if="url != null && url != ''">
            <h2>Puede continuar con su registro en la PLATAFORMA ONLINE para conectarse el día del evento</h2>
            <v-btn x-large color="primary" dark :href="url" target="_blank">
              <v-icon class="mr-2">mdi-message-video</v-icon> REGÍSTRESE AQUÍ
            </v-btn>
          </div>
          <v-divider class="my-5 py-5" v-if="url != null && url != ''" />
          <div class="mt-5 text-center">
            <v-btn class="ma-2" text color="indigo" :to='`/event/${eventId}`'>
              Regresar a la Página del evento
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
	export default {
		name: 'AvisoPostRegistro',
		props: {
			show: Boolean,
			url: String,
			eventId: String
		}
	}
</script>

<style lang="scss" scoped>

</style>