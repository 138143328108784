<template>
	<div>
		<h1>AQUI DEBE APARECER EL CREDENCIAL PARA IMPRIMIR</h1>
		<h2>USER ID: {{ $route.params.register_id }}</h2>
	</div>

</template>

<script>
export default {
	name: 'ticketRegister',
	data() {
		return {
			register: {}
		}
	},
}
</script>